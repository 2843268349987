@media only screen and (max-width:992px){
    .aller-badge-parent .inner-badge{display: table-column !important;}
}
@media only screen and (max-width:667px){
    .aller-badge-parent .inner-badge{display: table-column !important;}
}
 @media only screen and (max-width:320px){
    .aller-badge-parent .inner-badge{display: table-column !important;}
}
.aller-badge-parent {
    display: grid;
    padding: 20px;
    gap: 20px;
    grid-template-columns: repeat(1, 1fr);
}
.aller-badge-parent .allergies-reclose {
    position: absolute;
    right: 20px;
    top: 24px;
}
.aller-badge-parent .allergies-reclose:hover{
    cursor: pointer;
}
.aller-badge-parent h6{
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 19.5018px;
line-height: 124.5%;
}
.aller-badge-parent .inner-badge{
    display: flex;
    width:100%;
    position: relative;
}
.aller-badge-parent .make-badge{
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 10px 20px 10px;
    display: inline-flex;
    background: linear-gradient(91.33deg, #FC2B6E 0%, #BC00A9 100%);
    border-radius: 100px;
    margin-right: 10px;
}
.aller-badge-parent .make-badge p{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
color: #ffff;
}
.aller-badge-parent .make-badge p:hover{
    cursor: pointer;
}
.aller-badge-parent img.center-aligned-icon {
    position: absolute;
    left: 20px;
}
.no-data {
    display: block;
    /* width: 50%; */
    margin: auto;
    text-align: -webkit-center;
    .p{
        font-size: 18px;
        font-style: oblique;
    }
}
.cross-button{
    position: absolute;
    top: 5%;
    right: 7%;
}

.HeaderContainer .sub-closeIcon{
    width: 32px;
    position: absolute;
    right: 35px;
    top:10px;
}
.crosssignheader{
    position: absolute;
    right: 20px;
    top: 10px;
    @media screen and (max-width:320px){
        top: 63px;
        right: 20px;
    
    }
}
.crosssignheader:hover{
    cursor: pointer;
}
.HeaderContainer {
    width: 100%;
    margin: 0 auto;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #F8F8FB;
  
}

.Headerwrap {

    // margin-left: 450px;
    // display: flex;
    // justify-content: center;
    margin-top: 0px;
    padding: 0 10px;

    h4 {

        font-family: 'Jost';
        font-style: normal;
        font-weight: 500;
        font-size: 21.7674px;
        line-height: 31px;
        color: #202020;
    }

    p {

        font-family: 'Jost';
        font-style: normal;
        font-weight: 400;
        font-size: 16.9302px;
        line-height: 24px;
        letter-spacing: 0.75px;

        color: #B7B7C1;
    }
}

.HeaderCard {
    display: flex;

    .backTomenu{
        border: none;
        margin-right: 15px;
        background: none;
    }

    img {

        background: #FFFFFF;
        border: 0.878571px solid #000000;
        border-radius: 5.85714px;
    }
}

.NumppyImg {
    width: 80px;
    height: 80px;
    border-radius: 50% !important;
    // object-fit: cover;
}

.HeaderSearch {

    position: relative;
    display: flex;
    align-items: center;



    input {


        width: 287px;
        height: 47px;
        margin-right: 35px;
        background: #FFFFFF;
        border-radius: 10px;
        border: none;
        padding: 0 60px;

        font-family: 'Jost';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        /* identical to box height */


        color: #050505;
    }


    img {

        width: 17px;

        margin-right: -10px;
        height: 27px;
        width: 35px;

    }

    // float: left;
    // padding: 0 10px;
}

.HeaderSearch img:hover{
    cursor: pointer;
}


@media only screen and (max-width: 768px) {
    .HeaderContainer {

        flex-direction: column;
       
    }

    .HeaderSearch {

        input {
            width: 95%;
            margin: 10px 0;
        }
    }
}

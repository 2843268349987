.mui-drawer-wrapper {
  & .MuiPaper-root {
    // border-top-left-radius: 20px;
    // border-top-right-radius: 20px;
    height: 100vh;
    background-color: #ffffff;
    // background-color: #000000;
    // opacity:0.6;
    // height: 201px;
  }
}


.FoodContainer {
  width: 100%;
  margin: 0 auto;
  background-color: white !important;
}

.CloseIcon {
  width: 32px;
  height: 32px;
  position: absolute;
  right: 3%; 
  top: 7%;
  cursor: pointer;
}

.FoodWrapper {  
  display: flex;
  width: 100%;
  border-top-left-radius: 10px;
  position: relative;
  height: 269px;
  display: flex;
  justify-content: center;
  border-radius: 0 0 16px 16px;
  overflow: hidden;

  .selectedItembg {
    z-index: 1;
    position: relative;
    // width: 100%;
    // height: 269px;
    width: 420px;
    height: 100%;
    object-fit: cover;
    // border-radius: 0 0 16px 16px;
  }

  .blur{
    position: absolute;
    inset: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    filter: blur(5px);
  }
}

.QarWrapper {
  width: 87px;
  height: 35px;
  background: #010101;
  border-radius: 15px 0px 0px 0px;
  position: absolute;
  right: 0px;
  top: 166px;
  text-align: center;
}

.QarPrice {
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
  letter-spacing: 0.02em;
  color: #f55a2c;
}

.FoodDetailsContainer {
  background: #000000;
  opacity: 0.9;
}

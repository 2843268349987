.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorBottom.css-9emuhu-MuiPaper-root-MuiDrawer-paper {
  background: #fff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  height:auto;
}

.sectionSpacing {
  border: 1px solid #d1cece;
  box-shadow: 0px 3px 4px rgb(141 141 141 / 25%);
}


.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  overflow-y: unset;
}

.ant-drawer-body {
  padding: 0 !important;
}

.ant-rate .ant-rate-star-first, :where(.css-dev-only-do-not-override-1okl62o).ant-rate .ant-rate-star-second {
  color: #30303042 ;
}
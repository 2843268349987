@media only screen and (max-width: 1400px) {
  .FoodDetailsCntainers {
    width: 96% !important;
  }
}
@media only screen and (max-width: 992px) {
  .FoodDetailsCntainers {
    width: 95% !important;
  }
}
@media only screen and (max-width: 768px) {
  .FoodDetailsCntainers {
    width: 95% !important;
  }
}
@media only screen and (max-width: 667px) {
  .FoodDetailsCntainers {
    width: 89% !important;
  }
}
@media only screen and (max-width: 480px) {
  .FoodDetailsCntainers {
    width: 89% !important;
  }
}
@media only screen and (max-width: 380px) {
  .FoodDetailsCntainers {
    width: 89% !important;
  }
}
.mui-item-wrapper {
  & .MuiPaper-root {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: auto;
    background: #ffffff;
  }
}

.FoodDetailsCntainers {
  width: 97%;
  margin: 0 auto;
  padding-bottom: 80px;
}

.RecoomandDetailsWrappers {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 19.5018px;
    line-height: 124.5%;
    color: #202020;
  }
}

.ReCloseIcon {
  width: 12px;
  height: 12px;
}
.ReCloseIcon:hover {
  cursor: pointer;
}

.RecommandMainContainer {
  display: grid;
  /* display: flex; */
  /* justify-content: space-between; */
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  @media only screen and (max-width: 1225px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media only screen and (max-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 375px) {
    grid-template-columns: repeat((1, 1fr));
  }

  display: flex;
  overflow-y: scroll;
}


.ReSizeContainer {
  width: 95%;
  margin: 0 auto;
}

.ReColorWrapper {
  background: #f8f8fb;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-bottom: 12px;
  // width: 95%;
  // margin: 0 auto;
}

.RecoomandDetailsContainer {
  margin-top: 19px;
  img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    height: 130px;
    object-fit: contain;
  }
  p {
    font-family: "Jost";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;

    color: #202020;
  }

  h5 {
    font-family: "Jost";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: #f65a29;
    // margin-top: 7px;
  }
}

.ReButtonContainer {
  display: flex;
  justify-content: space-between;

  h5 {
    color: #fc2b6e;
  }
}

.ReButtonWrapper {
  display: flex;
  width: 53px;
  height: 23px;
  justify-content: space-between;
  background: #ffffff;

  margin-right: 5px;
  border-radius: 5px;
  outline-color: #e1e1e1;
  outline-style: auto;

  button {
    border: none;
    width: 20px;
    background: #ffffff;
    float: right;
  }
}

.ViewFood {
  position: relative;
  width: 100%;
  height: 201px;
  border-radius: 20px;
}

.QarWrapper {
  width: auto !important;
  max-width: 130px;
  background: #010101;
  border-radius: 15px 0px 0px 0px;
  position: absolute;
  right: 0px;
  top: 156px;
  padding: 5px 8px;
  height: auto !important;
  text-align: center;
}

.QarPrice {
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
  letter-spacing: 0.02em;
  color: #f55a2c;
}

.FoodDetailsContainer {
  opacity: 0.9;
}

// .icon1 {
//   position: absolute;
//   top: 11px;
//   left: 291px;
// }

// .icon2 {
//   position: absolute;
//   top: 11px;
//   left: 315px;
// }

.FoodGet {
  width: 53px;
  height: 23px;
  background: linear-gradient(91.33deg, #fc2b6e 0%, #bc00a9 100%);
  border-radius: 6.25377px;
  border-style: none;
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 12.3512px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin-top: -15px;
}


